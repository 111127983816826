import { i18n, LANGUAGES } from '@askable/i18n';
import { Button, IconButton, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { Captions as CaptionsIcon, CaptionsOffIcon, Check } from 'lucide-react';

import type { Captions } from './Captions/useHandleCaptions';
import type { PlayerUiSize } from './types';

type ListComponentProps = {
  label: string;
  onPress: () => void;
  isSelected: boolean;
};

const ListComponent = ({ label, onPress, isSelected }: ListComponentProps) => {
  return (
    <Button onClick={onPress} className="text-white" justifyContent="start">
      <div style={{ width: 20, marginRight: 10 }}>{isSelected ? <Check size={20} /> : null}</div>
      {label}
    </Button>
  );
};

type Props = {
  size: PlayerUiSize;
  captions: Captions;
};

export const SubtitlesPopover = ({ size, captions }: Props) => {
  const { locale, setTranscriptLocale } = captions;
  return (
    <Popover placement="top" trigger="hover" isLazy strategy="fixed">
      <PopoverTrigger>
        <IconButton
          aria-label={i18n.t('playback.subtitles.title', { defaultValue: 'Subtitles' })}
          variant="ghost"
          icon={captions.locale ? <CaptionsIcon /> : <CaptionsOffIcon />}
          size={size}
          onClick={() => setTranscriptLocale(undefined)}
        />
      </PopoverTrigger>
      <PopoverContent border="none" padding="3" background="black">
        <div className="flex flex-col gap-2">
          <h3 className="text-white">{i18n.t('playback.subtitles.title', { defaultValue: 'Subtitles' })}</h3>
          <ListComponent
            label={i18n.t('playback.subtitles.off', { defaultValue: 'Off' })}
            onPress={() => setTranscriptLocale(undefined)}
            isSelected={!locale}
          />
          {captions.transcriptLanguages?.map(transcriptLocale => {
            return (
              <ListComponent
                label={LANGUAGES.find(language => language.code === transcriptLocale.replace('_', '-'))?.label || ''}
                onPress={() => setTranscriptLocale(transcriptLocale)}
                isSelected={locale === transcriptLocale}
              />
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
};
