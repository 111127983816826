import { useEffect } from 'react';

import type { Dispatch, RefObject, SetStateAction } from 'react';
import type ReactPlayer from 'react-player';

type Subtitles = {
  start: number;
  end: number;
  text: string;
};

export type Captions = {
  locale: string | undefined;
  subtitles: Subtitles[];
  setTranscriptLocale: Dispatch<SetStateAction<string | undefined>>;
  transcriptLanguages: string[] | null;
};

export const useHandleCaptions = ({
  playerRef,
  captions,
  videoId,
}: {
  playerRef: RefObject<ReactPlayer>;
  captions?: Captions;
  videoId?: string;
}) => {
  const video = playerRef?.current?.getInternalPlayer();
  const { subtitles, locale } = captions || {};

  /* gets the current text track or creates a new one for the current locale */
  useEffect(() => {
    if (!video) return;

    const textTracks = Array.from((video.textTracks as TextTrackList) || []);
    const textTrack = textTracks.find(track => track.language === locale?.toString() && track.label === videoId);

    let track: TextTrack;

    /* hides all tracks if there is no locale or subtitles */
    if (!locale || !subtitles) {
      textTracks.forEach(textTrack => {
        textTrack.mode = 'hidden';
      });
      return;
    }

    if (textTrack) {
      /* returns the existing track if there is one */
      textTrack.mode = 'showing';
      track = textTrack;
    } else {
      /* if not, creates a new track, and adds cues */
      const newTrack = video.addTextTrack('subtitles', videoId, locale);
      newTrack.mode = 'showing';
      /* each sentence is a cue */
      subtitles.forEach(subtitle => {
        newTrack.addCue(new VTTCue(subtitle.start / 1000, subtitle.end / 1000, subtitle.text ?? ''));
      });

      track = newTrack;
    }

    /* hides all other tracks */
    textTracks.forEach(textTrack => {
      if (textTrack.language !== track.language || textTrack.label !== videoId) {
        textTrack.mode = 'hidden';
      }
    });
  }, [video, locale, videoId]);
};
